
import React, {Component} from "react";
import Layout from "../components/layout";
import styles from "./styles/contact.module.css";
import Title from "../components/title";
import { SocialIcon } from "react-social-icons";

class Contact extends Component{

    constructor(props){
        super(props);
    }

    render(){
        return(
            <Layout>
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <h1>Contact Me</h1>
                        <ul>
                            <li><a href="https://www.facebook.com/takahide.nakamura.52" className={styles.facebook}></a></li>
                            <li><a href="https://www.instagram.com/tnakvfx/" className={styles.instagram}></a></li>
                            <li><a href="https://vimeo.com/takahide" className={styles.vimeo}></a></li>
                            <li><a href="#" className={styles.twitter}></a></li>
                            <li><a href="https://www.linkedin.com/in/takahide-nakamura-8a7778a2/" className={styles.linkedin}></a></li>
                        </ul>
                    </div>

                    <div className={styles.message}>
                        <h2>Feel free to contact me anytime!</h2>
                        <a href="mailto:me@takavfx.com">me@takavfx.com</a>
                        <span>OR</span>
                    </div>

                    
                    
                    <form action="https://formspree.io/me@takavfx.com" method="POST" className={styles.form}>
                        <div>

                            <input className={styles.inputField} required type="text" placeholder="Name" name="name" />
                        </div>
                        
                        <div>

                            <input className={styles.inputField} type="email" placeholder="E-mail" name="_replyto" />
                        </div>

                        {/*

                        <div>
                            <label>Subject</label>
                            <input className={styles.inputField} name="_subject"/>
                        </div>
                        
                        */}

                        <div>
                            <textarea className={styles.inputField} name="message" placeholder="Message" rows="10"></textarea>
                        </div>
                        
                        <div>
                            <input className={styles.submit} required type="submit" value="Send" />
                        </div>
                    </form>

                </div>
            </Layout>
        );
    }
}

export default Contact;